import Vue from 'vue'
import VueGtag from 'vue-gtag'

export default ({ app }) => {
    Vue.use(VueGtag, {
        config: { id: process.env.NUXT_ENV_APP_ENV_NAME == 'production' ? 'G-RF9HN9BW1D' : 'dummy' },
        appName: 'Stan Store',
        disableAutoPageTrack: false,
        params: {
            send_page_view: false
        },
        pageTrackerScreenviewEnabled: true,
    }, app.router);
}