import { getSessionUUID, UUID } from './stan-analytics.js'

export default function ({ store, $axios, $cookies }) {
	$axios.onResponse(response => {
		// Populate client IP address from the latest API request
		if (typeof response.data === 'object' && response.data.ip) {
			store.commit('setIPAddress', response.data.ip)
		}
	})

	$axios.interceptors.request.use(request => {
		const token = store.state.auth_token
		if (token) {
			request.headers.common.Authorization = `Bearer ${token}`
		}
		request.headers.common['Bypass-Tunnel-Reminder'] = true
		request.headers.common['x-request-id'] = UUID()
		request.headers.common['x-session-uuid'] = getSessionUUID($cookies)

		return request
	})
}
