import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4877c3a5 = () => interopDefault(import('../pages/embed/index.vue' /* webpackChunkName: "pages/embed/index" */))
const _ee057c96 = () => interopDefault(import('../pages/healthcheck.vue' /* webpackChunkName: "pages/healthcheck" */))
const _0dc26bd9 = () => interopDefault(import('../pages/unavailable.vue' /* webpackChunkName: "pages/unavailable" */))
const _15c1b09d = () => interopDefault(import('../pages/auth/fan_login.vue' /* webpackChunkName: "pages/auth/fan_login" */))
const _65451e94 = () => interopDefault(import('../pages/course/components/Confetti.vue' /* webpackChunkName: "pages/course/components/Confetti" */))
const _3065a9e5 = () => interopDefault(import('../pages/course/components/CourseSidebar.vue' /* webpackChunkName: "pages/course/components/CourseSidebar" */))
const _11f2dd1e = () => interopDefault(import('../pages/course/components/ModuleLessonsContainer.vue' /* webpackChunkName: "pages/course/components/ModuleLessonsContainer" */))
const _1344d65b = () => interopDefault(import('../pages/affiliates/_uuid/index.vue' /* webpackChunkName: "pages/affiliates/_uuid/index" */))
const _0b217eca = () => interopDefault(import('../pages/cancellations/_booking_uuid.vue' /* webpackChunkName: "pages/cancellations/_booking_uuid" */))
const _714aaee6 = () => interopDefault(import('../pages/course/_course_slug.vue' /* webpackChunkName: "pages/course/_course_slug" */))
const _dfd91d74 = () => interopDefault(import('../pages/reschedulings/_booking_uuid.vue' /* webpackChunkName: "pages/reschedulings/_booking_uuid" */))
const _068f3c08 = () => interopDefault(import('../pages/subscribe/_username/index.vue' /* webpackChunkName: "pages/subscribe/_username/index" */))
const _86b2c47a = () => interopDefault(import('../pages/unsubscribe/_username/index.vue' /* webpackChunkName: "pages/unsubscribe/_username/index" */))
const _d3e1053a = () => interopDefault(import('../pages/subscribe/_username/_email.vue' /* webpackChunkName: "pages/subscribe/_username/_email" */))
const _582e8b08 = () => interopDefault(import('../pages/unsubscribe/_username/_email.vue' /* webpackChunkName: "pages/unsubscribe/_username/_email" */))
const _1479741b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2eeacb81 = () => interopDefault(import('../pages/_username/index.vue' /* webpackChunkName: "pages/_username/index" */))
const _7aa40f1c = () => interopDefault(import('../pages/_username/success.vue' /* webpackChunkName: "pages/_username/success" */))
const _4c0f8ccc = () => interopDefault(import('../pages/_username/p/_slug.vue' /* webpackChunkName: "pages/_username/p/_slug" */))
const _16fc61fc = () => interopDefault(import('../pages/_username/_slug/index.vue' /* webpackChunkName: "pages/_username/_slug/index" */))
const _6b56c136 = () => interopDefault(import('../pages/_username/_slug/page/_page_id.vue' /* webpackChunkName: "pages/_username/_slug/page/_page_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/embed",
    component: _4877c3a5,
    name: "embed"
  }, {
    path: "/healthcheck",
    component: _ee057c96,
    name: "healthcheck"
  }, {
    path: "/unavailable",
    component: _0dc26bd9,
    name: "unavailable"
  }, {
    path: "/auth/fan_login",
    component: _15c1b09d,
    name: "auth-fan_login"
  }, {
    path: "/course/components/Confetti",
    component: _65451e94,
    name: "course-components-Confetti"
  }, {
    path: "/course/components/CourseSidebar",
    component: _3065a9e5,
    name: "course-components-CourseSidebar"
  }, {
    path: "/course/components/ModuleLessonsContainer",
    component: _11f2dd1e,
    name: "course-components-ModuleLessonsContainer"
  }, {
    path: "/affiliates/:uuid",
    component: _1344d65b,
    name: "affiliates-uuid"
  }, {
    path: "/cancellations/:booking_uuid?",
    component: _0b217eca,
    name: "cancellations-booking_uuid"
  }, {
    path: "/course/:course_slug?",
    component: _714aaee6,
    name: "course-course_slug"
  }, {
    path: "/reschedulings/:booking_uuid?",
    component: _dfd91d74,
    name: "reschedulings-booking_uuid"
  }, {
    path: "/subscribe/:username",
    component: _068f3c08,
    name: "subscribe-username"
  }, {
    path: "/unsubscribe/:username",
    component: _86b2c47a,
    name: "unsubscribe-username"
  }, {
    path: "/subscribe/:username?/:email",
    component: _d3e1053a,
    name: "subscribe-username-email"
  }, {
    path: "/unsubscribe/:username?/:email",
    component: _582e8b08,
    name: "unsubscribe-username-email"
  }, {
    path: "/",
    component: _1479741b,
    name: "index"
  }, {
    path: "/:username",
    component: _2eeacb81,
    name: "username"
  }, {
    path: "/:username/success",
    component: _7aa40f1c,
    name: "username-success"
  }, {
    path: "/:username/p/:slug?",
    component: _4c0f8ccc,
    name: "username-p-slug"
  }, {
    path: "/:username/:slug",
    component: _16fc61fc,
    name: "username-slug"
  }, {
    path: "/:username/:slug/page/:page_id?",
    component: _6b56c136,
    name: "username-slug-page-page_id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
