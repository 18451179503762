export function setCountry(state, country) {
	state.country = country
}

export function setUserPrimaryColor(state, value) {
	state.userPrimaryColor = value
}

export function setUser(state, user) {
	state.user = user
}

export function setIPAddress(state, value) {
	state.ipAddress = value
}

export function setLibphonenumberLoaderStart(state, status) {
	state.libphonenumberLoader.started = status
}

export function setLibphonenumberLoaderCompleted(state, status) {
	state.libphonenumberLoader.completed = status
}

export function setPurchasedProducts(state, purchasedProducts) {
	state.purchasedProducts = purchasedProducts
}

export function setAuthToken(state, auth_token) {
	state.auth_token = auth_token
}
