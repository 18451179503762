const bucket = process.env.NUXT_ENV_S3_PREVIEW_BUCKET
const publicUrl = process.env.NUXT_ENV_S3_PUBLIC_URL
const delimiter = '/msc/'
const prefix = 'preview'

const s3Utils = (url, options = {}) => {}

s3Utils.getPreviewInfo = async (videoUrl, targetDir) => {
  const fullPath = videoUrl.split(delimiter)
  const timeInMs = Date.now()
  if (fullPath.length !== 2) {
    return { success: false }
  }
  const paths = fullPath[1].split('/')
  if (paths.length < 2) {
    return { success: false }
  }
  const videoFile = paths.slice(-1)[0]
  const filename = videoFile.substr(0, videoFile.lastIndexOf('.')) + '.jpg'
  const s3dir = paths.slice(0, -1).join('/')
  return {
    success: true,
    url: videoUrl,
    path: `${targetDir}/${paths.slice(0, -1).join('_')}_${timeInMs}`,
    s3path: `${prefix}/${s3dir}/${filename}`,
    previewUrl: `${publicUrl}/${prefix}/${s3dir}/${filename}`,
    bucket,
    filename,
  }
}

export { s3Utils }

// export default async () => { Object.defineProperty(Vue.prototype, '$s3Utils', { value: s3Utils, configurable: true })}
export default ({ app }, inject) => {
  app.$s3Utils = s3Utils
  inject('s3Utils', s3Utils)
}