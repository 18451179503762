import { getFCP } from "web-vitals";

export default function ({ app: { router, $stanAnalytics}, store }) {
  // Report site loading statistics to analytics
  function sendMetric(payload) {
    $stanAnalytics('fcp', {
      meta: {username: payload.context.username}, 
      props: {
        routeName: payload.context.routeName,
        value: parseInt(payload.metric.value, 10)}}
      )
  }
  
  function webVitals({ route }) {
    const context = {
      routeName: route.name,
      username: route.params.username || 'guest'
    };
    try {
      getFCP((metric) => sendMetric({ context, metric }));
    } catch (error) {
      console.error("[Analytics]", error);
    }
  }

  router.onReady((route) => { 
    webVitals({ route }) 
    router.afterEach((route) => webVitals({ route }));
  })
}