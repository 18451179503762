export default () => ({
	user: null,
	userPrimaryColor: '#5383ff',
	ipAddress: null,
	country: 'US',
	libphonenumberLoader: {
		started: false,
		completed: false,
	},
	purchasedProducts: [],
	auth_token: null,
})
