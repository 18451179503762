export function getUser(state) {
	return state.user
}

export function getUserPrimaryColor(state) {
	return state.userPrimaryColor
}

export function getIPAddress(state) {
	return state.ipAddress
}

export function getLibphonenumberLoadStarted(state) {
	return state.libphonenumberLoader.started
}

export function getLibphonenumberLoadCompleted(state) {
	return state.libphonenumberLoader.completed
}

export function getPurchasedProducts(state) {
	return state.purchasedProducts
}

export function getAuthToken(state) {
	return state.authToken
}
